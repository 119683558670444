export const PermissaoUsuarioEnum = {
	VISUALIZAR: "VISUALIZAR_USUARIO",
	CRIAR: "CRIAR_USUARIO",
	EXCLUIR: "EXCLUIR_USUARIO",
};

export const PermissaoGrupoUsuarioEnum = {
	VISUALIZAR: "VISUALIZAR_GRUPO_USUARIO",
	CRIAR: "CRIAR_GRUPO_USUARIO",
	EXCLUIR: "EXCLUIR_GRUPO_USUARIO",
};

export const PermissaoGrupoSeguroEnum = {
	VISUALIZAR: "VISUALIZAR_GRUPO_SEGURO",
	CRIAR: "CRIAR_GRUPO_SEGURO",
	EXCLUIR: "EXCLUIR_GRUPO_SEGURO",
};

export const PermissaoTipoVeiculoEnum = {
	VISUALIZAR: "VISUALIZAR_TIPO_VEICULO",
	CRIAR: "CRIAR_TIPO_VEICULO",
	EXCLUIR: "EXCLUIR_TIPO_VEICULO",
};

export const PermissaoEspecieVeiculoEnum = {
	VISUALIZAR: "VISUALIZAR_ESPECIE_VEICULO",
	CRIAR: "CRIAR_ESPECIE_VEICULO",
	EXCLUIR: "EXCLUIR_ESPECIE_VEICULO",
};

export const PermissaoComposicaoVeiculoEnum = {
	VISUALIZAR: "VISUALIZAR_COMPOSICAO_VEICULO",
	CRIAR: "CRIAR_COMPOSICAO_VEICULO",
	EXCLUIR: "EXCLUIR_COMPOSICAO_VEICULO",
};

export const PermissaoTipoSinistroEnum = {
	VISUALIZAR: "VISUALIZAR_TIPO_SINISTRO",
	CRIAR: "CRIAR_TIPO_SINISTRO",
	EXCLUIR: "EXCLUIR_TIPO_SINISTRO",
};

export const PermissaoTipoDespesaSinistroEnum = {
	VISUALIZAR: "VISUALIZAR_TIPO_DESPESA_SINISTRO",
	CRIAR: "CRIAR_TIPO_DESPESA_SINISTRO",
	EXCLUIR: "EXCLUIR_TIPO_DESPESA_SINISTRO",
};

export const PermissaoEstadoEnum = {
	VISUALIZAR: "VISUALIZAR_ESTADO",
	CRIAR: "CRIAR_ESTADO",
	EXCLUIR: "EXCLUIR_ESTADO",
};

export const PermissaoCidadeEnum = {
	VISUALIZAR: "VISUALIZAR_CIDADE",
	CRIAR: "CRIAR_CIDADE",
	EXCLUIR: "EXCLUIR_CIDADE",
};

export const PermissaoIntegranteEnum = {
	VISUALIZAR: "VISUALIZAR_INTEGRANTE",
	CRIAR: "CRIAR_INTEGRANTE",
	EXCLUIR: "EXCLUIR_INTEGRANTE",
};

export const PermissaoVeiculoEnum = {
	VISUALIZAR: "VISUALIZAR_VEICULO",
	CRIAR: "CRIAR_VEICULO",
	EXCLUIR: "EXCLUIR_VEICULO",
};

export const PermissaoVistoriadorEnum = {
	VISUALIZAR: "VISUALIZAR_VISTORIADOR",
	CRIAR: "CRIAR_VISTORIADOR",
	EXCLUIR: "EXCLUIR_VISTORIADOR",
};

export const PermissaoSinistroEnum = {
	VISUALIZAR: "VISUALIZAR_SINISTRO",
	CRIAR: "CRIAR_SINISTRO",
	EXCLUIR: "EXCLUIR_SINISTRO",
};

export const PermissaoPaisEnum = {
	VISUALIZAR: "VISUALIZAR_PAIS",
	CRIAR: "CRIAR_PAIS",
	EXCLUIR: "EXCLUIR_PAIS",
};

export const PermissaoModeloVeiculoEnum = {
	VISUALIZAR: "VISUALIZAR_MODELO_VEICULO",
	CRIAR: "CRIAR_MODELO_VEICULO",
	EXCLUIR: "EXCLUIR_MODELO_VEICULO",
};

export const PermissaoMotoristaEnum = {
	VISUALIZAR: "VISUALIZAR_MOTORISTA",
	CRIAR: "CRIAR_MOTORISTA",
	EXCLUIR: "EXCLUIR_MOTORISTA",
};

export const PermissaoFornecedorEnum = {
	VISUALIZAR: "VISUALIZAR_FORNECEDOR",
	CRIAR: "CRIAR_FORNECEDOR",
	EXCLUIR: "EXCLUIR_FORNECEDOR",
};

export const PermissaoVistoriaEnum = {
	VISUALIZAR: "VISUALIZAR_VISTORIA",
	CRIAR: "CRIAR_VISTORIA",
	EXCLUIR: "EXCLUIR_VISTORIA",
};

export const PermissaoDespesaSinistroEnum = {
	VISUALIZAR: "VISUALIZAR_DESPESA_SINISTRO",
	CRIAR: "CRIAR_DESPESA_SINISTRO",
	EXCLUIR: "EXCLUIR_DESPESA_SINISTRO",
};

export const PermissaoVendedorEnum = {
	VISUALIZAR: "VISUALIZAR_VENDEDOR",
	CRIAR: "CRIAR_VENDEDOR",
	EXCLUIR: "EXCLUIR_VENDEDOR",
};

export const PermissaoPenalizacaoEnum = {
	VISUALIZAR: "VISUALIZAR_PENALIZACAO",
	CRIAR: "CRIAR_PENALIZACAO",
	EXCLUIR: "EXCLUIR_PENALIZACAO",
};

export const PermissaoTipoVistoriaEnum = {
	VISUALIZAR: "VISUALIZAR_TIPO_VISTORIA",
	CRIAR: "CRIAR_TIPO_VISTORIA",
	EXCLUIR: "EXCLUIR_TIPO_VISTORIA",
};

export const PermissaoFerramentasEnum = {
	VISUALIZAR: "VISUALIZAR_FERRAMENTAS",
	CRIAR: "CRIAR_FERRAMENTAS",
	EXCLUIR: "EXCLUIR_FERRAMENTAS",
};

export const PermissaoConsultaFipeEnum = {
	VISUALIZAR: "VISUALIZAR_CONSULTA_FIPE",
	CRIAR: "CRIAR_CONSULTA_FIPE",
	EXCLUIR: "EXCLUIR_CONSULTA_FIPE",
};
